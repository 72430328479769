.page{
    display: flex;
    flex-direction: column;
    min-height: calc((var(--vh) * 100) - 50px);

    .page-title{
        padding: 19px 12px 22px;
    }

    .page-content-wrap{
        border-top: 1px solid var(--primary-element-color);
        min-height: 100%;
        flex: 1;
        padding: 19px 12px 22px;
        background-color: var(--secondary-bg-color);
    }

    .third-lvl-menu{
        padding-left: 12px;
    }
}

.exhibitions-page{

    .page-title{
        padding: 18px 15px 18px 22px !important;
    }

    .page-content-wrap{
        padding: 15px 15px 22px 22px !important;
    }
}

.stand-page{

    .page-title{
        display: flex;
        align-items: center;

        span{
            margin-left: 5px;
            font-size: 22px;
            font-weight: 400;
        }
    }

    .page-content{
        width: 100%;

        &-wrap{
            width: 100%;
            display: flex;
            flex: 1;
            box-sizing: border-box;
        }
    }

    .stand-type-info{
        .photo-carousel{
            margin-bottom: 80px;
        }
    }

    .stand-details{
        h4.blue,
        h4.inverted{
            margin-bottom: 18px;
        }
    }

    .stand-equipments{

    }

    .stand-tasks{
        flex:1;

        .left-col{
            background-color: RGBA(var(--active-bg-color-rgb) / var(--opacity10));
            margin: -19px 0 -19px -12px;

            min-height: 100%;
        }

        .right-col{
            padding: 2px 24px;

        }
    }
}