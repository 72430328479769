.check-list-comment-field{
    width: 100%;

    .comment-field-wrap{
        display: flex;

        & > * + * {
            margin-left: 10px;
        }

        .comment-data{
            flex: 1;

            textarea{
                min-height: 30px !important;
                font-weight: 400;
                background-color: RGB(var(--rgb-yellow) / var(--opacity20)) !important;
                border-color: RGB(var(--rgb-yellow) / var(--opacity20)) !important;
                line-height: 1.3em;
            }
        }
    }
}