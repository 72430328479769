@import 'scss/base/helpers';

.task-list{
    overflow: auto;
    max-height: calc(100 * var(--vh) - 288px);
    padding: 16px 10px 30px 16px;

    &::-webkit-scrollbar {
        width: 20px;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        @extend %transition;
        background-color: var(--primary-element-color2);
        border-radius: 20px;
        border: 6px solid transparent;
        background-clip: content-box;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: var(--disabled-bg-color);
    }

    &-wrap{

    }

    &-item{
        @extend %transition;
        border: 1px solid var(--primary-element-color2);
        padding: 11px;
        background-color: var(--secondary-bg-color);

        border-radius: var(--border-radius);

        &.focused{
            border-color: var(--primary-element-active-color);
            background-color: RGBA(var(--active-bg-color-rgb) / var(--opacity20)) !important;
        }

        &:hover{
            border-color: var(--primary-element-active-color);
            background-color: var(--primary-bg-color);
        }

        & + &{
            margin-top: 12px;
        }

        .row{
            display: flex;
            font-family: var(--font-family);

            &.state{
                align-items: center;
                justify-content: space-between;
            }

            &.info{
                font-weight: 600;
                font-size: 14px;
                color: var(--label-text-color);
                display: flex;
                align-items: center;
                margin-bottom: 5px;
                margin-top: 5px;

                .type{
                    color: var(--active-text-color);
                    margin-right: 5px;
                }

                .cad-number{
                    color: var(--label-text-color);
                    margin-left: 5px;
                }
            }

            &.title{
                color: var(--primary-text-color);
                font-size: 16px;
                line-height: 19px;
                font-weight: 600;
            }

            &.additional-info{
                margin-top: 13px;
                justify-content: space-between;

                & > *{
                    display: flex;
                    align-items: center;
                    font-size: 12px;
                    color: var(--label-text-color2);
                    line-height: 1em;

                    .anticon{
                        margin-right: 5px;
                        font-size: 16px;
                    }
                }

                .assign-to{
                    flex: 6;
                }

                .comments{
                    display: flex;
                    flex: 1;
                    justify-content: end;
                }

                .date{
                    display: flex;
                    flex: 4;
                    justify-content: end
                }
            }
        }
    }
}

.task-comment-list{
    &-wrap{
        border-top: 1px solid var(--primary-element-color2);
    }

    &-item{
        padding: 14px 0 18px;
        border-top: 1px solid var(--primary-element-color);

        .row{
            & + .row{
                margin-top: 10px;
            }
        }

        .comment-info{
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;

            .author{
                font-weight: 600;
            }

            .date{
                color: var(--label-text-color);
                font-size: 12px;
                margin-right: 10px;
            }
        }

        .action{
            display: flex;
            align-items: center;
        }

        .comment-text{

        }
    }
}

.task-file-list{
    &-wrap{

    }

    &-item{
        display: flex;
        align-items: center;

        & + &{
            margin-top: 5px;
        }

        a {
            display: flex;
            align-items: center;
            font-weight: 600;

            & > .anticon{
                font-size: 22px;
            }
        }

        .action{
            margin-left: 10px;

            .ant-btn{
                max-height: 22px;
            }

            .anticon{
                @extend %transition;
                color: var(--label-text-color);

                &:hover{
                    color: var(--active-text-color);
                }
            }
        }
    }
}