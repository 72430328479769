@import "scss/base/helpers";


.main-menu{
    display: flex;
    align-items: center;

    & > * + *{
        margin-left: 60px;
        line-height: 1.3em !important;
    }

    a{
        @extend %transition;
        color: RGB(var(--secondary-bg-color-rgb) / var(--opacity60));
        font-weight: bold;
        text-transform: uppercase;


        &.active,
        &:hover{
            color: RGB(var(--secondary-bg-color-rgb) / var(--opacity100));
        }

        &.active{
            border-bottom: 2px solid var(--active-bg-color);
        }
    }
}

.user-menu{
    display: flex;
    align-items: center;

    li{

        & + li{
            border-left: 1px solid RGB(var(--secondary-bg-color-rgb) / var(--opacity15));
        }

        &:last-child{
            & > *{
                padding-right: 0;
            }
        }

        & > *{
            padding: 4px 15px;
            display: flex;
            align-items: center;

            .icon,
            .text{
                @extend %transition;
                color: RGB(var(--secondary-bg-color-rgb) / var(--opacity60));
            }

            .icon{
                font-size: 28px;

                &.wBadge{
                    position: relative;
                    margin-right: 7px;

                    .ant-badge{
                        font-size: 12px;
                        position: absolute;
                        right: -10px;
                        top: 10px;

                        &-count{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 0;
                            line-height: 1.5em;
                            max-height: 15px;
                            background-color: var(--red);
                            box-shadow: none;
                            font-size: 12px;
                            border-radius: 7px;
                        }
                    }
                }
            }

            .text{
                font-size: 12px;
                margin-left: 8px;
                font-weight: 600;
            }

            &.ant-btn{
                min-width: auto !important;
            }

            &.ant-btn:before{
                display: none;
            }

            &:hover{
                .icon,
                .text{
                    color: var(--active-text-color);
                }
            }

            &.profile{
                height: 34px;
                box-sizing: border-box;

                .text{
                    font-size: 13px;
                    line-height: 1.3em;
                }
            }
        }

    }
}

.second-lvl-menu{
    padding : 0 3px;

    li + li{
        margin-top: 24px;
    }

    a{
        color: var(--label-text-color);
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .anticon{
            font-size: 30px;
        }

        .label{
            font: 600 12px/1.2em var(--font-family);
        }

        &.active > .label,
        &:hover > .label,
        &:hover,
        &.active{
            color: var(--active-text-color) !important;
        }
    }
}

.third-lvl-menu{
    display: flex;

    li + li{
        margin-left: 40px;
    }

    a {
        display: block;
        position: relative;
        font-size: 16px;
        font-weight: bold;
        color: var(--label-text-color);
        padding: 5px 0;

        &:after{
            @extend %transition;
            content: "";
            background-color: var(--active-bg-color);
            position: absolute;
            height: 0;
            width: 100%;
            left: 0;
            bottom: -1px;
            border-radius: 5px 5px 0 0;
        }

        &.active,
        &:hover{
            color: var(--active-text-color);
        }

        &.active{
            &:after{
                height: 3px;
            }
        }

        .check-icon{
            color: var(--green);
        }

        .loader-item{
            display: inline-flex;
            align-items: center;
            margin-left: 5px;

            .anticon{
                font-size: 16px;
                color: var(--green);
            }
        }

    }

}

.popover-menu{
    li{
        position: relative;

        &:before{
            background-color: RGB( var(--active-bg-color-rgb) / var(--opacity15) );
            content: "";
            position: absolute;
            opacity: var(--opacity0);
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }

        &:hover{
            &:before{
                opacity: var(--opacity100);
            }
        }

        .anticon{
            margin-right: 10px;
            font-size: 16px;
        }
    }

    a{
        min-height: 34px;
        padding: 0 15px;
        display: flex;
        align-items: center;
        position: relative;

        &:hover{

        }

    }

    button{
        border-radius: initial !important;
        background-color: transparent !important;
        text-align: left;
        padding: 0 15px !important;
        width: 100%;

        &:before{
            display: none !important;
        }

        .anticon + *{
            margin-inline-start: 0;
        }
    }
}