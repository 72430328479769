.graphics-print-data-file-list{

    &-item{
        display: flex;
        justify-content: space-between;
        align-items: center;

        .name{
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 600;

            .anticon{
                margin-right: 5px;
                font-size: 24px;

                &[aria-label="link icon"]{
                    font-size: 20px;
                    margin-right: 9px;
                }
            }
        }

        .actions{
            display: flex;
            align-items: center;

            .anticon{
                font-size: 18px;
            }

            .delete-button{
                margin-left: 10px;
            }
        }
    }
}


.graphics-layout-file-list{

    &-item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2px 0;

        .name{
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 600;
        }

        .actions{
            display: flex;
            align-items: center;

            .anticon{
                font-size: 18px;
            }


            .delete-button{
                margin-left: 10px;
            }
        }

        & + * {
            border-top: 1px solid var(--primary-element-color2);
        }
    }
}