.equipment-order-table{
    .name{
        display: flex;
        align-items: center;
        font-weight: 500;

        .img-wrap{
            width: 54px;
            height: 54px;
            border: 1px solid var(--primary-element-color2);
            margin-right: 20px;
            display: flex;
            align-items: center;
            border-radius: var(--border-radius);
            overflow: hidden;

            img{
                object-fit: contain;
                width: 100%;
            }
        }

        .text{
            flex: 5;
        }
    }

    .default-qty{
        color: var(--active-text-color);
        font-weight: 600;
    }
}