.color{

    &-primary{
        color: var(--primary-text-color) !important;

        &-op40{
            color: rgba(var(--primary-text-color-rgb),  var(--opacity40)) !important;
        }

        &-op60{
            color: rgba(var(--primary-text-color-rgb),  var(--opacity60)) !important;
        }
    }

    &-secondary{
        color: var(--secondary-text-color) !important;

        &-op40{
            color: rgba(var(--secondary-text-color-rgb),  var(--opacity40)) !important;
        }

        &-op60{
            color: rgba(var(--secondary-text-color-rgb),  var(--opacity60)) !important;
        }

    }

    &-active{
        color: var(--active-text-color) !important;
    }


    &-success{
        color: var(--success-color) !important;
    }

    &-warning{
        color: var(--warning-color) !important;
    }

    &-error{
        color: var(--error-color) !important;
    }

    &-disabled{
        color: rgba(var(--primary-text-color-rgb),  var(--opacity40)) !important;
    }
}

.bg{
    &-light{
        background-color: rgba(var(--secondary-bg-color-rgb), var(--opacity6));

        &-bordered{
            @extend .bg-light;
            padding: 13px 11px;
            border: 1px solid rgba(var(--secondary-bg-color-rgb), var(--opacity15));
            border-radius: 6px !important;

            &.magenta{
                border-color: var(--active-bg-color) !important;
            }
        }
    }
}

.text{
    color: var(--primary-text-color);

    &-bold{
        font-weight: bold;
    }

    &-active{
        color: var(--active-text-color);
    }


    &-dark{
        @extend .color-secondary;

        &-op40{
            @extend .color-secondary-op40;
        }

        &-op60{
            @extend .color-secondary-op60;
        }

        &-bold{
            @extend .text-bold;
        }
    }

    &-white{
        color: var(--primary-text-color);

        &-op40{
            @extend .color-primary-op40;
        }

        &-op60{
            @extend .color-primary-op60;
        }

        &-bold{
            @extend .text-bold, .text-white;
        }

    }

    &-error{
        color: var(--error-color);
    }

    &-type2{
        @extend .text;
        font-size: 16px;
    }

    &-type3{
        @extend .text;
        font-size: 12px;
        line-height: 1.33em;

        &-active{
            @extend .text-type3, .text-active;
        }

        &-white{
            @extend .text-type3, .text-white;

            &-op40{
                @extend .text-type3, .color-primary-op40;
            }

            &-op60{
                @extend .text-type3, .color-primary-op60;
            }

        }

        &-dark{
            @extend .text-type3, .text-dark;

            &-op40{
                @extend .text-type3, .color-secondary-op40;
            }

            &-op60{
                @extend .text-type3, .color-secondary-op60;
            }
        }

        &-error{
            @extend .text-type3, .text-error;
        }
    }

    &-type4{
        @extend .text;
        font-size: 10px;
        line-height: 1.2em;
    }

    ////////////////

    &-label{
        @extend .color-primary-op40, .text;
        font-size: 10px;
        text-transform: uppercase;

        &-dark{
            @extend .text-label, .color-secondary-op60;
        }
    }

}