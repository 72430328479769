.stand-type-list{

    &-orientations{
        display: flex;
        flex-wrap: wrap;

        li{
            display: flex;
            margin-right: 20px;
            margin-top: 8px;

            &:last-child{
                margin-right: 0;
            }

            .img-wrap{
                margin-right: 5px;

                img{
                    border: 1px solid var(--primary-element-active-color);
                    display: block;
                    width: 20px;
                    height: 20px;
                }
            }

            .title{
                font-size: 14px;
                line-height: 1.5em;
                font-weight: 600;
                margin: 0;
                font-family: var(--font-family);
            }
        }
    }

    &-file{
        &-wrap{
            .loader-item{
                justify-content: center;
                display: flex;
                margin: 5px 0;

                .anticon{
                    font-size: 24px;
                }
            }

            .no-data{
                margin: 10px 0 5px;

                p{
                    padding: 0 !important;
                }
            }
        }

        &-item{
            display: flex;
            padding-bottom: 12px;

            &:last-child{
                padding-bottom: 0;
            }

            & + * {
                padding-top: 12px;
                border-top: 1px solid var(--primary-element-color);
            }

            .icon{
                font-size: 36px;
                line-height: 1em;
                color: var(--label-text-color);
            }

            .info{
                flex: 1;
                padding: 0 5px;

                h4{
                    line-height: 1.3em;
                }

                p{
                    font-size: 12px;
                    color: var(--label-text-color);
                }
            }

            .action{
                a{
                    font-size: 20px;
                }
            }
        }
    }
}