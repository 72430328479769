@import "scss/base/helpers";

.stand-cad-version-list{

    .ant-collapse-header {
        padding: 0 !important;
        border-top: 1px solid var(--primary-element-color2);

        .cad-version{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 0;

            p {
                display: flex;
                align-items: center;
                font-weight: 600;

                .version{
                    margin-right: 5px;
                }

                &.count{
                    padding: 0 10px;
                }

                .number{
                    color: var(--label-text-color) !important;
                }
            }

            .no-file{
                color: var(--label-text-color) !important;
                font-size: 12px;
                font-weight: 600;
            }
        }
    }

    .version-list{
        margin-left: 35px;



        .version{
            align-items: center;
            border-top: 1px solid var(--primary-element-color);
            color: var(--label-text-color) !important;
            display: flex;
            font-family: var(--font-family);
            font-weight: 600;
            justify-content: space-between;
            padding: 10px 0;

            & > *:first-child{
                flex:  1;
            }

            .date{
                margin-right: 10px;
            }

            .anticon{
                font-size: 20px;
            }

            .no-file{
                font-weight: 600;
                font-size: 12px;
            }

        }
    }

    .ant-collapse-item:last-child{
        .version-list{
            .version:last-child{
                padding-bottom: 0;
            }
        }
    }
}