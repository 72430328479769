.exhibition-selector{
    .pre-text{
        color: var(--label-text-color);
        margin-right: 5px;
    }

    .current-ee{
        font-weight: bold;
        color: var(--active-text-color);

    }
}