.exhibitions-table{


    .stand-count{
        display: flex;
        text-align: center;

        p{
            padding: 0 4px;
            display: flex;
            flex-direction: column;
        }

        & > p + p{
            border-left : 1px solid var(--primary-element-bg-color);
        }

        .in-process{
            color: var(--orange);
        }

        .completed{
            color: var(--green);
        }

        .count{
            font: 400 14px/1.3em var(--font-family);
        }
    }

    .status{
        border-radius: 14px;
        padding: 3px 7px;
        font: bold 14px/1.2em var(--font-family) !important;
        text-align: center;
        text-transform: capitalize;

        &.new{
            background-color: RGB( var(--rgb-blue) / var(--opacity20) );
            color: var(--active-text-color);
        }

        &.in_process{
            background-color: RGB( var(--rgb-orange) / var(--opacity20) );
            color: var(--orange);

        }

        &.finished {
            background-color: RGB( var(--rgb-green-dark) / var(--opacity20) );
            color: var(--green-dark);
        }
    }
}