.stand-orientation-select{

    .ant-select-selection-item{
        display: flex;
        align-items: center;

        .img-wrap {
            width: 20px;
            height: 20px;
            border: 1px solid var(--primary-element-color2);
            margin-right: 5px;

            img{
                display: inline-block;
                width: 100%;
                object-fit: contain;
                height: 100%;
            }
        }
    }

    &-popup{

        .ant-select-item{
            &-option-content{
                display: flex;
                align-items: center;

                .img-wrap{
                    width: 30px;
                    height: 30px;
                    border: 2px solid var(--primary-element-active-color);
                    border-radius: var(--border-radius);
                    margin-right: 10px;

                    img{
                        display: inline-block;
                        width: 100%;
                        object-fit: contain;
                        height: 100%;
                    }
                }

                .title{

                }
            }
        }

    }
}

