@import "scss/base/helpers";

header{
    background-color: var(--header-bg-color);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 54px;
    display: flex;
    z-index: 1000;

    .logo {
        .anticon{
            font-size: 54px;
            color: var(--active-text-color);
        }

        a{
            display: block;

            &:active,
            &:focus {
                outline: 0;
                border: none;

            }
        }
    }

    .container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 30px 0 80px;
        flex: 1;
    }
}

.sidebar-block{
    @extend %transition;
    width: 58px;
    border-right: 1px solid var(--primary-element-color);
    background-color: var(--secondary-bg-color);
    display: flex;
    justify-content: center;
    position: fixed;
    top: 54px;
    left: -58px;
    bottom: 0;
    padding-top: 12px;
}

.wSidebar{
    .sidebar-block{
        left: 0;
    }
}

.table-header-block{
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    .filter-part{
        display: flex;
        gap: 10px;
    }
}

.filter-form-block{

    .add-filter-wrap{
        padding: 14px 20px;
        border-bottom: 1px solid var(--primary-element-color);

        & > *{
            width: 100%;
        }
    }

    .filter-field-list{
        height: calc((var(--vh) * 100) - 180px);
        overflow: auto;

        .filter-item-block{
            padding: 12px 20px 14px;
            border-bottom: 1px solid var(--primary-element-color);

            .label{
                padding-bottom: 0 !important;
            }

            & > *{
                display: flex;

                &:first-child{
                    justify-content: space-between;
                    align-items: center;

                    .ant-btn{
                        color: var(--label-text-color);
                    }
                }

                &:last-child{
                    gap: 12px;
                    & > *:not(.ant-btn) {
                        flex: 1;
                    }
                }
            }
        }
    }

    .form-actions{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        position: absolute;
        bottom: 0;
        padding: 18px 20px;
        box-shadow: 0 -2px 8px 0 rgba(0, 0, 0, 0.11);
        background-color: var(--secondary-bg-color);
        z-index: 10;
        box-sizing: border-box;
    }
}

.card-block{
    background-color: RGB(var(--rgb-blue) / var(--opacity8));
    border: 1px solid var(--primary-element-color2);
    border-radius: var(--border-radius);

    & + &{
        margin-top: 13px;
    }

    &-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 11px 12.5px;
        border-bottom: 2px solid var(--primary-element-color);
        text-transform: uppercase;

        h4{
            margin: 0 !important;
        }

        &-action{
            & > *{
                height: 21px;
                font-weight: 600;
            }
        }
    }

    &-body{
        padding: 11px 12.5px 12px;
    }
}

.page-header-block{
    .row{
        padding: 14px 12px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

}