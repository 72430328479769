.graphics-orders-table{
    .order-status-wrap{
        display: flex;

        .tag-standard{
            min-width: 100px;
        }
    }

    .stand-info{
        color: var(--active-text-color);
        font-weight: 600;
    }

    .comment-wrap{
        display: flex;
        align-items: flex-start;


        .anticon{
            color: var(--active-text-color);
            font-size: 20px;
            margin-right: 5px;
        }
    }

    .price{
        box-shadow: inset 0 0 0 100em rgb(var(--warning-color-rgb) / var(--opacity25));
    }

    .total{
        box-shadow: inset 0 0 0 100em rgb(var(--danger-color-rgb) / var(--opacity25));
    }
}