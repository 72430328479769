@import "scss/base/helpers";

.hall-plan{
    .uploaded-file{
        display: flex;
        align-items: center;

        a {
            @extend %transition;
            display: flex;
            align-items: center;
            height: 30px;
            margin-right: 5px;
            color: var(--primary-text-color);

            span.anticon{
                margin-right: 7px;
            }

            span{
                font-size: 14px;
                font-weight: bold;
                letter-spacing: 0;
            }

            &:hover{
                color: var(--active-text-color);
            }
        }

        .anticon{
            font-size: 18px !important;
        }
    }
}