@import "scss/base/helpers";

.check-list-btn{

    .loader-item{
        margin: 0 !important;
    }

    .important &.important-btn{
        background-color: RGB(var(--rgb-red) / var(--opacity20)) !important;
        border-color: RGB(var(--rgb-red) / var(--opacity20)) !important;
        color: var(--red);
    }

    &.delete-comment-btn{
        border: none !important;
        color: var(--label-text-color);
    }

    &.check-btn{
        background-color: var(--primary-element-bg-color) !important;
        border-color: var(--primary-element-bg-color) !important;

        &:not(.loading){
            .anticon{
                @extend %transition;
                opacity: 0;
            }

            &:hover{
                .anticon{
                    opacity: 1;
                }
            }
        }

        &.checked{
            background-color: RGB(var(--rgb-green) / var(--opacity20)) !important;
            border-color: RGB(var(--rgb-green) / var(--opacity20)) !important;

            .anticon{
                color: var(--green) !important;
            }

            &:not(.loading){
                .anticon{
                    opacity: 1;
                }

                &:hover{
                    .anticon{
                        opacity: 0;
                    }
                }
            }
        }
    }
}