.task-cad-upload{

    &-wrap{
        border: 1px dashed var(--primary-element-active-color);
        min-height: 76px;
        display: flex;
        box-sizing: border-box;
        border-radius: var(--border-radius);
        background-color: RGB(var(--active-bg-color-rgb) / var(--opacity15));

        &.file-exist{
            border: 1px solid RGB(var(--success-color-rgb) / var(--opacity20));
            background-color: RGB(var(--success-color-rgb) / var(--opacity20));
        }

        .cad-file{
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex: 1;
            padding: 20px 25px;

            .text{
                color: var(--success-color);
                font-size: 16px;
                display: flex;
                font-weight: 600;

                .icon-wrap{
                    width: 28px;
                    height: 28px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: var(--success-color);
                    color: var(--secondary-text-color);
                    margin-right: 12px;

                    .anticon{
                        font-size: 16px;
                    }
                }
            }

            .actions{
                display: flex;

                & > * + *{
                    margin-left: 10px;
                }

                .link-button{
                    background-color: var(--success-color) !important;

                    .anticon{
                        font-size: 20px;
                    }
                }

                .ant-btn-default{
                    border: 1px solid var(--label-text-color);
                    background-color: transparent;
                    color: var(--label-text-color);

                    &:hover,
                    &:active{
                        color: var(--danger-color) !important;
                        border-color: var(--danger-color) !important;
                    }
                }
            }
        }

        .ant-upload-wrapper{
            width: 100%;
            height: 76px;

            .ant-upload{
                width: inherit;
                height: inherit;

                .upload-cad{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

}