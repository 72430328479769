.graphics-order-edit-form{

    .field-highlight {

        &.warn{

            .ant-input-number{
                background-color: RGB(var(--active-bg-color-rgb) / var(--opacity10)) !important;
            }

            .ant-input-number-group-addon{
                background-color: RGB(var(--active-bg-color-rgb) / var(--opacity10)) !important;
            }
        }

        &.important{

            .ant-input-number{
                background-color: RGB(var(--danger-color-rgb) / var(--opacity10)) !important;
                color: var(--danger-color) !important;
            }

            .ant-input-number-group-addon{
                background-color: RGB(var(--danger-color-rgb) / var(--opacity10)) !important;
            }

        }
    }
}