
.check-list-form-row{
    &-block{
        max-width: 80%;

        .row-title{
            color: var(--label-text-color);
            margin: 0 0 7px;
            font-size: 12px;
            text-transform: uppercase;
            font-weight: 600;
            line-height: 1.2em;
            max-width: 60%;
        }

        & + *{
            margin-top: 10px;
        }

        &.important{

            .field-group-wrap{
                .ant-input-number-input,
                .ant-input,
                .ant-picker{
                    color: var(--red) !important;
                    background-color: RGB(var(--rgb-red) / var(--opacity20)) !important;
                    border-color: RGB(var(--rgb-red) / var(--opacity20)) !important;

                    input{
                        color: var(--red) !important;
                    }
                }
            }
        }
    }

    &-data{
        display: flex;

        & > * + * {
            margin-left: 10px;
        }

        .important-flag-wrap,
        .checkbox-wrap{
            width: 30px;
        }
        .field-group-wrap{
            flex: 6;

            .ant-input-number{
                width: 100%;
                height: 30px;

            }

            .ant-input-number-input,
            .ant-input,
            .ant-picker{
                height: 30px;
            }
        }

        .comment-wrap{
            display: flex;
            flex: 4;

        }

        .grid-three-col{
            grid-column-gap: 10px;

            &.vice-versa{
                .col-one{
                    order: 2;
                }
                .col-two{
                    order: 1;
                }
            }
        }

        .grid-two-col{
            grid-column-gap: 10px;

            & > .col-two,
            & > .col-one{
                & > *{
                    & + * {
                        margin-top: 10px;
                    }
                }
            }
        }
    }



}