.task-comment-edit-form{
    border-top: 1px solid var(--primary-element-color);
    padding-top: 24px;

    .form-actions{
        margin-top: 14px;

        .ant-form-item{
            margin: 0;
        }
    }
}