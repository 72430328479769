.sign-in-form{
    background-color: var(--secondary-bg-color);
    width: 380px;
    border-radius: var(--border-radius);
    padding: 18px 0 30px;


    .logo{
        font-size: 50px;
        text-align: center;


        &-holder{
            padding-bottom: 16px;
            margin-bottom: 20px;
            border-bottom: 1px solid var(--primary-element-color);
        }

        .icon-bg{
            display: none;
        }

        .icon{
            fill : var(--primary-color) !important;
            transform: scale(1.2) translate(0px, -5px);
        }
    }

    h1{
        margin: 0 0 12px;
    }

    .ant-form{
        padding: 0 40px;

    }

    .form-actions{
        text-align: right;
    }
}