.equipment-info-block{
    padding: 20px;

    h3{
        margin: 20px 0 26px;

    }

    .attr-grid{
        grid-row-gap: 12px;
        grid-column-gap: 15px;
        margin-bottom: 12px;

        .label{
            line-height: 1.4em;
        }

        p{
            font-size: 14px;
            font-weight: 500;
        }
    }


    .description{
        margin-top: 26px;
        line-height: 1.4em;
    }

    .no-data{
        margin: 40px 20px;
        text-align: center;
    }
}