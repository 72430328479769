.ant-notification-topRight{
    top: 15px !important;
}

.ant-notification-notice{
    border-radius: 6px;
    font-size: 14px;
}

.ant-notification-notice-description,
.ant-notification-notice-message{
    font-size: 14px !important;
    line-height: 1.4em;
}

.ant-notification-notice-message{
    margin-top: 4px;
}

.ant-notification-notice-icon .anticon{
    font-size: 28px;
}

.ant-notification-notice:before{
    content: "";
    width: 10px;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    background-color: transparent;
}

.success-notification:before{
    background-color: #47C65E;
}

.success-notification .ant-notification-notice-icon .anticon{
    color: #47C65E;
}

.error-notification:before{
    background-color: #FD5F5F;
}

.error-notification .ant-notification-notice-icon .anticon{
    color: #FD5F5F;
}

.info-notification:before{
    background-color: #FFD800;
}

.info-notification .ant-notification-notice-icon .anticon{
    color: #FFD800;
}

@media (max-width: 767px) {
    .ant-notification-topRight{
        margin-right: 16px;
    }
}

.no-data{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-message{
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        color: inherit;

        &.before{
            margin: 0 0 15px;
        }

        &.after{
            margin: 15px 0 0;
        }
    }

    &.dark{
        color: var(--secondary-text-color);
    }

    &.block{
        height: calc( var(--vh) * 100 - 140px);
    }

    .icon-wrap{
        .anticon{
            font-size: 100px;
        }
    }
}