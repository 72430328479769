.tasks-table{

    .ant-table{
        border: none !important;
        border-bottom: 1px solid var(--primary-element-color2) !important;
        border-bottom-left-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);

        table{
            border-top-right-radius: var(--border-radius);
            border-top: 1px solid var(--primary-element-color2);


            td:last-child,
            th:last-child{
                border-right: 1px solid var(--primary-element-color2);
            }

            th:first-child{
                border-radius: initial !important;

                .table-section-label{
                    border-left: none;
                }
            }

            th:last-child{
                border-top-right-radius: var(--border-radius);
            }
        }

        &-content{
            border-left: 1px solid var(--primary-element-color2);
            border-radius: var(--border-radius);
        }

        &-content{
            padding-top: 25px;
        }
    }

    .general-info + .task-info,
    .task-info + .cad-info,
    .cad-info + .general-info{
        border-left: 1px solid var(--primary-element-color2);
    }

    thead{
        th.cad-info{
            background-color: var(--special-bg-color_3) !important;
        }

        th.task-info{
            background-color: var(--special-bg-color_4) !important;
        }
    }

    .stand-info{
        color: var(--active-text-color);
        font-weight: 600;
    }

    .actions{
        background-color: var(--secondary-bg-color) !important;
    }

    .table-section-label{
        color: var(--primary-text-color);
        border-top-right-radius: var(--border-radius);
        border-top-left-radius: var(--border-radius);
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 400;
        border: 1px solid var(--primary-element-color2);
        background-color: inherit;
        position: absolute;
        top: -25px;
        left: 0;
        min-width: 80px;
        height: 23px;
        display: flex;
        align-items: center;
        padding: 0 30px 0 10px;
    }
}