.graphics-print-data-link-modal,
.graphics-order-create-modal{
    .ant-modal-body{
        min-width: 450px;
    }
}

.graphics-print-data-drawer{
    .ant-drawer-body{
    min-width: 550px!important;
    }
}
