.check-list-form{
    h3{
        color: var(--header-bg-color);
        font-weight: 600;
        font-size: 18px;
        margin: 0 0 8px;
        line-height: 1.75em;
    }

    & + *{
        margin-top: 36px;
    }

    &:last-child{
        margin-bottom: 60px;
    }
}