.stand-type-block{

    &-description{
        dt{
            padding-bottom: 0;
        }

        dd + dt{
            margin-top: 12px;
        }

        dd{
            font-size: 14px;
            line-height: 1.5em;
            font-weight: 600;
            margin: 0;
            font-family: var(--font-family);
        }

        .navision-number{
            font-size: 16px;
            line-height: 1.5em;
            font-weight: 600;
            color: var(--active-text-color);
        }
    }
}