.graphics-orders-block{
    background-color: transparent;
    border: initial;
    border-radius: initial;

    .ant-collapse{
        &-item{
            border-radius: var(--border-radius) !important;
            border-bottom: initial;

            .ant-collapse-header{
                background-color: var(--primary-bg-color);
                border-radius: var(--border-radius) !important;
                border: 1px solid var(--primary-element-color2);
                display: flex;

                & > .ant-collapse-expand-icon{
                    width: 24px !important;
                    height: 24px !important;

                    .plus-minus-toggle{
                        &:before,
                        &:after{
                            width: 14px;
                            height: 2px;
                            left: 5px;
                        }
                    }
                }
            }

            &-active{
                .ant-collapse-header{
                    border-radius: var(--border-radius) var(--border-radius) 0 0 !important;
                }

                .ant-collapse-content-box{
                    border-radius: 0 0  var(--border-radius) var(--border-radius) !important;
                }
            }

            .ant-collapse-content-box{
                border: 1px solid var(--primary-element-color2);
                border-top: initial;
                padding: 20px 16px !important;
            }

            & + *{
                margin-top: 10px;
            }
        }
    }
}

.graphics-order-collapse-header{
    display: flex;
    align-items: center;
    justify-content: space-between;

    .order-number-wrap{
        color: var(--active-text-color);
        font-size: 16px;
        font-weight: 600;
        flex-basis: 40px;
    }

    .order-status-wrap{
        display: flex;
        flex-basis: 25%;
        padding: 0 10px;

        & > *{
            min-width: 100px;
        }
    }

    .order-info-wrap{
        display: flex;
        flex-basis: 55%;

        & > *{
            padding: 0 10px;
            border-left: 1px solid var(--primary-element-color2);
        }

        .dimensions{
            flex-basis: 25%;
            font-weight: 600;

            span{
                color: var(--label-text-color);
            }
        }
    }


    .print-data-wrap{
        padding-left: 10px;
        flex-basis: 14%;
    }
}

.graphics-order-page-actions{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.graphics-print-data-block{

    .print-data-type{

        .header{
            padding: 11px 18px 12px 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: RGB(var(--rgb-blue) / var(--opacity8));
            border-top: 1px solid var(--primary-element-color2);

            h4{
                color: var(--label-text-color);
                text-transform: uppercase;
                font: 600 14px / 1.5em var(--font-family);
            }
        }

        .data{
            padding: 11px 18px 12px 20px;
        }
    }
}

.graphics-approval-layout-block{
    .layout-status{
        width: 100%;
        margin-bottom: 10px;

        .ant-select-selector{
            border: transparent !important;
            background-color: var(--secondary-element-color) !important;
        }
    }
}