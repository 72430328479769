.message-list{
    &-item{


        & + &{
            border-top: 1px solid var(--primary-element-color2);
            padding-top: 5px;
            margin-top: 10px;
        }

        .info{
            display: flex;
            align-items: center;
            justify-content: space-between;

            .created{
                display: flex;

                .author{
                    font-weight: 600;
                }

                &-at{
                    color: var(--label-text-color);
                    margin-right: 5px;
                    display: flex;
                    align-items: center;

                    &:after{
                        content: "";
                        height: 1em;
                        width: 1px;
                        line-height: 1em;
                        background-color: var(--primary-element-color2);
                        display: block;
                        margin-left: 5px;
                    }
                }
            }
        }

        .message{
            color: var(--primary-text-color);
            font-family: var(--font-family);
            white-space: pre-line;
        }
    }

}