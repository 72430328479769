.tasks-header-block{
    padding: 15px 21px 12px 21px;
    border-bottom: 1px solid var(--primary-element-color2);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title *{
        margin: 0 !important;
    }
}

.task-view-block{
    .info{
        display: flex;
        align-items: center;
        justify-content: space-between;

        .ant-btn{
            font-size: 14px;
            background-color: RGB(var(--active-bg-color-rgb) / var(--opacity15)) !important;

            &:hover{
                background-color: RGB(var(--active-bg-color-rgb) / var(--opacity100)) !important;
                color: var(--secondary-text-color) !important;
            }
        }
    }

    .title{
        margin: 8px 0 0;

        & + .parameters{
            margin-top: 16px;
        }
    }

    .parameters{
        & + .parameters{
            margin-top: 12px;
        }

        & > *{
            display: flex;
            flex-direction: column;
            justify-content: center;

            &:last-child{
                flex: 1;
            }
        }

        .text{
            font-weight: 600;
            font-size: 14px;
        }
    }

    .description{
        margin-top: 21px;
        white-space: pre-line;
        overflow-wrap: break-word;
    }

    .status-field{
        & .ant-select{
            width: 70%;
        }
    }

    & > .task-file-list-wrap{
        margin-top: 28px;
    }

    .task-cad-upload-wrap{
        margin-top: 20px;
    }
}

.task-comments-block{
    margin-top: 35px;

    .loader-block{
        min-height: 100px !important;
    }
}