@import "scss/base/helpers";

.public-app{
    background: url("../../assets/app_login_back_image.png") no-repeat 0 0 RGB(var(--active-bg-color-rgb) / var(--opacity40));;
    background-size: cover;
    min-height: calc(var(--vh) * 100);

    display: flex;
    align-items: center;
    justify-content: center;

    h1{
        color: var(--active-text-color);
        font-size: 16px;
        text-align: center;
    }
}


.app{

    background-color: var(--primary-bg-color);
    min-height: calc(var(--vh) * 100);


    .wSidebar{
        padding-left: 58px;
    }

    main{
        @extend %transition;
        padding-top: 54px;

    }


}