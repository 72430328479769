.stand-comment-edit-modal{
    .ant-modal-body{
       padding: 15px;
        min-width: 450px;
    }
}

.stand-cad-change-current-modal{
    .ant-modal-body{
        min-height: 160px !important;
        min-width: 450px;
    }
}

.stand-change-size-type-modal{
    .ant-modal-body{
        max-width: 600px;
    }

    .warning{
        background-color: RGBA(var(--rgb-red) / var(--opacity25));
        padding: 10px 18px !important;
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 1em;

        .anticon{
            font-size: 24px;
            color: var(--error-color);
            margin-right: 20px;
        }
    }
}