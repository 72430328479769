.page-back-button{
    font-size: 14px;
    color: var(--label-text-color);
    font-weight: 600;
    display: flex;
    align-items: center;

    .anticon{
        margin-right: 5px;
        transform: rotate(-180deg);
    }
}