.stand-equipment-order-block{

    .ant-collapse-item{
        &:not(&-active){
            .ant-collapse-header{
                border-radius: var(--border-radius) !important;
            }
        }

        .ant-collapse-header{
            background-color: var(--primary-bg-color);
            border-radius: var(--border-radius) var(--border-radius) 0 0 !important;
            border: 1px solid var(--primary-element-color2);

            .ant-collapse-header-text{
                display: flex;
                align-items: center;
                justify-content: space-between;

                .action{
                    .ant-btn{
                        font-weight: 500;
                    }
                }
            }

            .ant-collapse-expand-icon{
                width: 24px !important;
                height: 24px !important;

                .plus-minus-toggle{
                    &:before,
                    &:after{
                        width: 14px;
                        height: 2px;
                        left: 5px;
                    }
                }
            }
        }
    }

    .ant-collapse-content{
        border-radius: 0 0 var(--border-radius) var(--border-radius) !important;

        .ant-table{
            border-radius: 0 0 var(--border-radius) var(--border-radius) !important;
            border-start-start-radius: 0;
            border-start-end-radius: 0;
        }

        .ant-table thead th:first-child,
        .ant-table thead td:last-child{
            border-radius: 0 !important;
        }

        .ant-table thead th,
        .ant-table thead td{
            padding: 7px 12px !important;
        }
    }

    & + &{
        margin-top: 14px;
    }
}

.stand-cad-info-block{
    .cad-versions-wrap{
        border-top: 1px solid var(--primary-element-color);
        background-color: var(--secondary-bg-color);
        padding: 11px 12.5px 12px;
        margin: -2px -12.5px -12px;
    }
}