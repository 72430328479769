.stand-cad-info-form{
    margin-bottom: 16px;

    .ant-input{
        background-color: var(--secondary-bg-color) !important;
        border-color: var(--secondary-element-color) !important;
    }

    .ant-select{
        width: 100%;

        &-selector{
            background-color: var(--secondary-bg-color) !important;
            border-color: var(--secondary-element-color) !important;
        }
    }
}

.stand-cad-status-form{
    .cad-version{
        display: flex;
        align-items: center;
        justify-content: space-between;

        .anticon{
            font-size: 20px;
        }

        &-wrap{
            margin-bottom: 10px;
        }

        p {
            display: flex;
            align-items: center;
            font-weight: 600;

            .version{
                margin-right: 5px;
            }

            .number{
                color: var(--label-text-color) !important;
            }
        }

        .no-file{
            color: var(--label-text-color) !important;
            font-size: 12px;
            font-weight: 600;
        }
    }
}

.stand-edit-form{

    .ant-select-disabled{

    }

    .stand-type-wrap,
    .stand-size-wrap{
        display: flex;

        & > * + *{
            margin-left: 10px;
        }

        button{
            font-weight: 600;
        }
    }

    .stand-type-wrap{
        & > *:first-child{
            flex: 1;
        }

        button{
            margin-top: 25px;
        }
    }
}